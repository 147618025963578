import gql from 'graphql-tag';

export default gql`
query getAllItemPathOfTeamInGroup($teamIdArray: [Int!], $groupId: Int!) {
  teams_items(where: {
    team_id: { _in: $teamIdArray },
    _or: [
      { project: {group_id: { _eq: $groupId }}},
      { folder: {group_id: { _eq: $groupId }}}
    ]
  }
  ) {
    folder {
      item_path
    }
    project {
      item_path
    }
  }
}
`;
