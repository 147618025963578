var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        { staticClass: "mr-4 dark-grey-buttons", attrs: { to: _vm.backRoute } },
        [
          _c("b-icon", {
            staticClass: "top--2",
            attrs: { icon: "arrow-left-circle-fill", "aria-hidden": "true" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }